import React from "react"
import SEO from "../components/seo"
import Chapter8 from "src/views/Chapter8"
import OGImage from "../images/seo/radio_dzieciom.jpg"

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Audycje dla dzieci"
        description="Programy dla najmłodszych,  słuchowiska i bajki. Bogata historia audycji dla dzieci w Polskim Radiu"
        image={OGImage}
      />
      <Chapter8 />
    </>
  )
}

export default IndexPage
