import React, { useRef } from "react"
import { useArticles } from "src/hooks"
import { Iterator } from "src/util"
import TitleAndParagraph from "src/components/TitleAndParagraph"
import ArticleRows from "src/components/ArticleRows"
import NextChapterLink from "src/components/NextChapterLink"
import VideoRows from "src/components/VideoRows"
import Gallery from "src/components/Gallery"
import BodyWrap from "src/components/BodyWrap"
import { AnimatePresence, motion } from "framer-motion"
import H1 from "src/components/H1"

const Chapter8 = () => {
  const articles = useArticles({ articleCategory: 8969 })
  const iterator = useRef()

  return (
    <AnimatePresence exitBeforeEnter>
      {articles.list?.length > 0 &&
        (iterator.current = new Iterator({ array: articles.list })) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <BodyWrap>
              <H1 />
              <TitleAndParagraph article={iterator.current.current()} />
              <TitleAndParagraph article={iterator.current.next()} />
              <ArticleRows
                articles={iterator.current.next(3)}
                variant="medium"
              />
              <TitleAndParagraph article={iterator.current.next()} />
              <Gallery articles={iterator.current.next(6)} />
              <ArticleRows
                articles={iterator.current.next(3)}
                variant="short"
                notitle
                nophoto
              />
              <TitleAndParagraph article={iterator.current.next()} />
              <Gallery articles={iterator.current.next(9)} />
              <ArticleRows
                articles={iterator.current.next(3)}
                variant="short"
                notitle
                nophoto
              />
              <TitleAndParagraph article={iterator.current.next()} />
              <VideoRows article={iterator.current.next()} />
            </BodyWrap>
            <NextChapterLink
              title="Do usłyszenia, do zobaczenia"
              href="do-uslyszenia-do-zobaczenia"
            />
          </motion.div>
        )}
    </AnimatePresence>
  )
}

export default Chapter8
