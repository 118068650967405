import React from "react"
import VideoPlayer from "src/components/VideoPlayer"
import { Wrapper, Cell, Description } from "./styles"

const getVideo = extraFields => {
  return extraFields?.filter(field => field?.Name?.toLowerCase() === "video")
}

const getDescriptions = extraFields => {
  return extraFields?.filter(field => field?.Name?.toLowerCase() === "desc")
}

const VideoRows = ({ article = { CustomFields: [] } } = {}) => {
  return (
    <Wrapper>
      {getVideo(article.CustomFields).map((a, index) => {
        return (
          <Cell>
            <VideoPlayer src={a.Value} />
            <Description>
              {getDescriptions(article.CustomFields)[index]?.Value}
            </Description>
          </Cell>
        )
      })}
    </Wrapper>
  )
}
export default VideoRows
